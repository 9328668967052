<script setup lang="ts">
import ProcessingAISnackbar from "@/modules/storyboards/components/ProcessingAISnackbar.vue";
import { useStoryboardsStore } from "@/modules/storyboards/store";
import ConfirmCancelAIGeneration from "@/modules/writtenContent/components/dialogs/ConfirmCancelAIGeneration.vue";

const storyboardsStore = useStoryboardsStore();
</script>

<template>
  <div v-for="(item, index) in storyboardsStore.aiTasks" :key="index">
    <ProcessingAISnackbar
      v-if="item"
      :key="'pas' + item.id"
      v-model:dialogVisible="item.visible"
      :position="index + 1"
      :taskItem="item"
      :storyboardData="item.storyboardData"
      :localization="item.localization"
    />
    <ConfirmCancelAIGeneration
      v-if="item"
      :key="'ccag' + item.id"
      v-model:dialogVisible="item.cancelVisible"
      :currentWrittenContent="item.id"
      @aiWrittenContentCancelProcess="item.cancelAction = true"
    />
  </div>
</template>
