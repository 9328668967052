<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useStoryboardsStore } from "@/modules/storyboards/store";
import { useSnackbar } from "@/shared/hooks/useSnackbar";

const task = defineModel<any>("taskItem");
const dialogVisibleMV = defineModel("dialogVisible");

const props = defineProps<{
  dialogVisible?: boolean;
  position: number;
  taskItem: any;
  storyboardData: any;
  localization: any;
}>();

defineEmits(["update:dialogVisible", "update:taskItem"]);

const itemName = computed(() => {
  return `${props.taskItem.storyboardData.name} [${props.localization.country.code}-${props.localization.language.code}]`;
});

const { pushNotification } = useSnackbar();
const storyboardsStore = useStoryboardsStore();
storyboardsStore.$onAction(({ name, args }) => {
  const [id, isSuccess] = args;

  if (name === "setStoryboardAITaskSuccess" && id === task.value?.id) {
    if (isSuccess) {
      pushNotification(
        "success",
        `Transcription for ${itemName.value} successfully translated.`,
        {
          timeout: -1,
        },
      );
      storyboardsStore.removeStoryboardAITask(task.value!.id);
    } else {
      pushNotification(
        "error",
        `An error occurred while translating content for “${itemName.value}” using AI.`,
        {
          timeout: -1,
        },
      );
      storyboardsStore.removeStoryboardAITask(task.value!.id);
    }
  }
});

onMounted(() => {
  task.value!.isAiGenerationInProgress = true;
});
</script>
<template>
  <EccaSnackbar
    v-model="dialogVisibleMV"
    :color="'info'"
    :prependIcon="'$StoreBuildingWarehouse'"
    class="processing-ai-snackbar pt-10"
    :style="`transform: translateY(${position * 120}px)`"
    minWidth="620"
  >
    <div class="ecca-snackbar__text font-weight-bold">
      Transcription for {{ itemName }} is being translated. It can take up to 5
      minutes.
    </div>
    <VProgressLinear
      class="processing-ai-snackbar__progress"
      indeterminate
      color="secondary"
    />
  </EccaSnackbar>
</template>

<style scoped>
.processing-ai-snackbar__progress {
  width: 100%;
  margin: 10px 0;
}
</style>
