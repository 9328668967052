import { defineStore } from "pinia";
import { ComputedRef } from "vue";
import { StoryboardDialogTypes } from "@/modules/storyboards/types";
import { IdType, Storyboard } from "@/modules/types";

const initialState = {
  isSyndicationDialogOpen: false,
  isSidePanelExpanded: true,
  dialogs: {
    delete: false,
    deleteVideo: false,
    deleteLocalization: false,
    createNew: false,
    edit: false,
    uploadFile: false,
    createNewLocalization: false,
  } as Record<StoryboardDialogTypes, boolean>,
  dialogsData: {
    uploadFiles: {
      storyboardId: undefined as string | undefined | IdType,
      storyboardType: "" as string,
    },
  },
  previewMobile: false,
  activeElementName: "",
  storyboardData: {} as Storyboard,
  currentTranslationTotalWords: 0,
  currentBacktranslationTotalWords: 0,
  aiTasks: [] as any[],
};

export const useStoryboardsStore = defineStore("storyboards", {
  state: () => ({ ...initialState }),
  getters: {
    getStoryboardAITaskById: (state) => {
      return (id: string) =>
        state.aiTasks.find((task) => {
          if (task.id === id) {
            return task;
          }
        });
    },
  },
  actions: {
    changeDialogStatus(
      type: StoryboardDialogTypes,
      value: boolean,
      stack: boolean = false,
    ) {
      if (value && !stack) this.resetDialogStatus();
      this.dialogs[type] = value;
    },
    showDialog(type: StoryboardDialogTypes, stack?: boolean) {
      this.changeDialogStatus(type, true, stack);
    },
    hideDialog(type: StoryboardDialogTypes) {
      this.changeDialogStatus(type, false);
    },
    resetDialogStatus() {
      this.dialogs = {
        ...initialState.dialogs,
      };
    },
    resetSidePanelState() {
      this.isSidePanelExpanded = initialState.isSidePanelExpanded;
    },
    setStoryboardData(payload: Storyboard) {
      this.storyboardData = payload;
    },
    setTranslationTotalWords(words: number) {
      this.currentTranslationTotalWords = words;
    },
    setBacktranslationTotalWords(words: number) {
      this.currentBacktranslationTotalWords = words;
    },
    addStoryboardAITask(item: any) {
      const task = { ...item, visible: true };
      this.aiTasks.push(task);
    },
    async setStoryboardAITaskSuccess(id: IdType, isSuccess: boolean) {
      return new Promise((resolve) => {
        const index = this.aiTasks.findIndex((item: any) => item.id === id);
        if (index !== -1) {
          this.aiTasks[index].isAiGenerationSuccess = isSuccess;
        }
        resolve(true);
      });
    },
    removeStoryboardAITask(id: IdType) {
      const index = this.aiTasks.findIndex((item: any) => item.id === id);
      if (index !== -1) {
        this.aiTasks.splice(index, 1);
      }
    },
  },
});
