<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { computed, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import StoryboardAITasks from "@/modules/storyboards/components/StoryboardAITasks.vue";
import WrittenContentAITasks from "@/modules/writtenContent/components/WrittenContentAITasks.vue";
import DebugBar from "@/shared/ui/DebugBar.vue";
import NotificationDialog from "@/shared/ui/dialogs/NotificationDialog.vue";
import UiSnackbars from "@/shared/ui/UiSnackbars.vue";
import { useAppStore } from "@/store/app";
import { useMsgStore } from "@/store/msgStore";

const route = useRoute();
const store = useAppStore();
const msgStore = useMsgStore();
msgStore.init();

const userInfo = computed(() => store.userInfo);
const isDebugBarVisible = computed(() => {
  return (
    import.meta.env.MODE == "development" ||
    import.meta.env.VITE_VUE_PROD_DEVTOOLS
  );
});

watch(
  route,
  () => {
    store.resetErrors();
  },
  { deep: true },
);
watch(userInfo, () => {
  Sentry.setUser({
    id: userInfo.value?.sub,
  });
});

onBeforeMount(() => {
  store.validateNotificationPermission();
});
</script>

<template>
  <VApp>
    <RouterView :key="store.clientID.value" />
    <UiSnackbars />
    <WrittenContentAITasks />
    <StoryboardAITasks />
    <NotificationDialog v-model:dialogVisible="store.notificationDialogOpen" />
    <DebugBar v-if="isDebugBarVisible" />
  </VApp>
</template>

<style lang="scss">
@import "styles/main.scss";
</style>
